import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner"; // Import loader

const WithdrawalRateChargePage = () => {
  const [withdrawalCharges, setWithdrawalCharges] = useState([]);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [modalData, setModalData] = useState({
    property_id: "",
    withdrawalfee: "",
  });

  const [isLoading, setIsLoading] = useState(false); // Add loading state

  useEffect(() => {
    fetchWithdrawalFee();
  }, []);

  const fetchWithdrawalFee = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        "https://backend.inyumba.co.ke/iNyumba/withdrawalfeecharge/fetchall",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setWithdrawalCharges(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching service charges:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleUpdateWithdrawalCharge = () => {
    setIsLoading(true); // Set loading state to true
    const token = localStorage.getItem("token");
    const { withdrawalfee } = modalData;
    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/withdrawalfeecharge/update/${selectedPropertyId}`,
        { withdrawalfee },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setIsModalOpen(false);
        fetchWithdrawalFee();
        toast.success(response.data.response);
        setIsLoading(false); // Set loading state to false
      })
      .catch((error) => {
        console.error("Error updating service charge:", error);
        toast.error(error.response.data.response);
        setIsLoading(false); // Set loading state to false
      });
  };

  const handleDeleteWithdrawal = (propertyId) => {
    const token = localStorage.getItem("token");
    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/withdrawalfeecharge/delete/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        fetchWithdrawalFee();
        toast.success(response.data.response);
      })
      .catch((error) => {
        console.error("Error deleting service charge:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleAddWithdrawalFee = () => {
    const token = localStorage.getItem("token");
    const { property_id, withdrawalfee } = modalData;
    axios
      .post(
        "https://backend.inyumba.co.ke/iNyumba/withdrawalfeecharge/add",
        { property_id, withdrawalfee },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsModalOpen(false);
        fetchWithdrawalFee();
        toast.success(response.data.response);
      })
      .catch((error) => {
        console.error("Error adding service charge:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleOpenUpdateModal = (withdrawCharge) => {
    setSelectedPropertyId(withdrawCharge.property_id);
    setModalData({
      property_id: withdrawCharge.property_id, // Added to modalData
      withdrawalfee: withdrawCharge.withdrawalfee,
    });
    setModalAction("Update"); // Track the action
    setIsModalOpen(true);
  };

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setModalData({ ...modalData, [name]: value });
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">
            Withdrawal Rate Charge
          </h1>
          <button
            onClick={() => {
              setModalAction("Add");
              setModalData({ property_id: "", withdrawalfee: "" });
              setIsModalOpen(true);
            }}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Add Withdrawal Fee
          </button>
          <table className="mt-4 w-full">
            <thead>
              <tr>
                <th className="px-4 py-2">PropertyId</th>
                <th className="px-4 py-2">Withdrawal Fee</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {withdrawalCharges.map((withdrawCharge) => (
                <tr key={withdrawCharge.property_id}>
                  <td className="border px-4 py-2">
                    {withdrawCharge.property_id}
                  </td>
                  <td className="border px-4 py-2">
                    {withdrawCharge.withdrawalfee}
                  </td>
                  <td className="border px-4 py-2">
                    <button
                      onClick={() => handleOpenUpdateModal(withdrawCharge)}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                    >
                      Update
                    </button>
                    <button
                      onClick={() =>
                        handleDeleteWithdrawal(withdrawCharge.property_id)
                      }
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {isModalOpen && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
            <div className="bg-white p-6 rounded-lg w-96">
              {modalAction === "Update" ? (
                <>
                  <h2 className="text-lg font-semibold mb-4">
                    Update Withdrawal Fee
                  </h2>
                  <input
                    type="text"
                    name="withdrawalfee"
                    value={modalData.withdrawalfee}
                    onChange={handleModalInputChange}
                    placeholder="Withdrawal Fee"
                    className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                  />
                  <div className="flex justify-end">
                    <button
                      onClick={handleUpdateWithdrawalCharge}
                      disabled={isLoading}
                      className={`${
                        isLoading
                          ? "bg-gray-500"
                          : "bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                      } text-white py-2 px-4 rounded-md focus:outline-none`}
                    >
                      {isLoading ? (
                        <Oval
                          height={20}
                          width={20}
                          color="white"
                          visible={true}
                          ariaLabel="loading-indicator"
                          secondaryColor="white"
                        />
                      ) : (
                        "Update"
                      )}
                    </button>

                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <h2 className="text-lg font-semibold mb-4">
                    Add Withdrawal Fee
                  </h2>
                  <input
                    type="text"
                    name="property_id"
                    value={modalData.property_id}
                    onChange={handleModalInputChange}
                    placeholder="Property ID"
                    className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                  />
                  <input
                    type="text"
                    name="withdrawalfee"
                    value={modalData.withdrawalfee}
                    onChange={handleModalInputChange}
                    placeholder="Withdrawal Fee"
                    className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                  />
                  <div className="flex justify-end">
                    <button
                      onClick={handleAddWithdrawalFee}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                    >
                      Add
                    </button>
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </AuthGuard>
    </EmailGuard>
  );
};

export default WithdrawalRateChargePage;
