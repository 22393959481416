import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Oval } from "react-loader-spinner";

const RentMpesaTopupsStatus = () => {
  const [statusData, setStatusData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCodes, setResultCodes] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");

      try {
        const [statusResponse, codesResponse] = await Promise.all([
          axios.get("https://backend.inyumba.co.ke/iNyumba/view/rentmpesatopupsstatus", {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get("https://backend.inyumba.co.ke/iNyumba/resultcodes", {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        setStatusData(statusResponse.data.response || []);
        setResultCodes(
          (codesResponse.data.response || []).reduce((acc, code) => {
            acc[code.code] = code.message;
            return acc;
          }, {})
        );
      } catch (error) {
        toast.error("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Oval color="#4A90E2" height={50} width={50} />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-semibold mb-4">Rent M-Pesa Top-up Status</h1>

      <div className="bg-white shadow-md rounded-md p-4">
        <table className="min-w-full border-collapse border border-gray-200">
          <thead>
            <tr className="bg-gray-200">
              <th className="py-2 px-4 border">Merchant Request ID</th>
              <th className="py-2 px-4 border">User ID</th>
              <th className="py-2 px-4 border">Checkout Request ID</th>
              <th className="py-2 px-4 border">Status</th>
              <th className="py-2 px-4 border">Result Message</th>
              <th className="py-2 px-4 border">Created At</th>
            </tr>
          </thead>
          <tbody>
            {statusData.length > 0 ? (
              statusData.map((status, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border">{status.merchantrequestid}</td>
                  <td className="py-2 px-4 border">{status.userid}</td>
                  <td className="py-2 px-4 border">{status.checkoutrequestid}</td>
                  <td className="py-2 px-4 border">{status.status}</td>
                  <td className="py-2 px-4 border">{status.resultcode ? resultCodes[status.resultcode] || "Unknown Error" : "No response"}</td>
                  <td className="py-2 px-4 border">{new Date(status.created_at).toLocaleString()}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="py-2 px-4 text-center">No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RentMpesaTopupsStatus;
