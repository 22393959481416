import React, { useState, useEffect } from "react";
import axios from "axios";
import { EmailGuard } from "../App";
import { AuthGuard } from "../App";

const RateLimit = () => {
  const [ipData, setIPData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchIPData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://backend.inyumba.co.ke/iNyumba/view/ratelimits",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIPData(response.data.response);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    fetchIPData();
  }, []);

  if (loading) {
    return <div className="text-center py-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center py-8 text-red-500">Error: {error}</div>;
  }

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Rate Limits</h1>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-md rounded-md">
              <thead>
                <tr className="bg-gray-200 text-gray-700">
                  <th className="py-2 px-4 text-left">ID</th>
                  <th className="py-2 px-4 text-left">IP Address</th>
                  <th className="py-2 px-4 text-left">Request Count</th>
                  <th className="py-2 px-4 text-left">Last Request At</th>
                  <th className="py-2 px-4 text-left">Created At</th>
                  <th className="py-2 px-4 text-left">Updated At</th>
                </tr>
              </thead>
              <tbody>
                {ipData.map((item) => (
                  <tr key={item.id} className="border-b hover:bg-gray-100">
                    <td className="py-2 px-4">{item.id}</td>
                    <td className="py-2 px-4">{item.ip_address}</td>
                    <td className="py-2 px-4">{item.request_count}</td>
                    <td className="py-2 px-4">{item.last_request_at}</td>
                    <td className="py-2 px-4">{item.created_at}</td>
                    <td className="py-2 px-4">{item.updated_at}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default RateLimit;