import React, { useState } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EmailGuard } from "../App";

const PendingPayments = () => {
  const [pendingData, setPendingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [propertyId, setPropertyId] = useState("");

  const fetchPendingData = async () => {
    if (!propertyId) {
      toast.error("Please enter a Property ID");
      return;
    }

    setLoading(true);
    const token = localStorage.getItem("token");
    
    try {
      const response = await axios.get(
        `https://backend.inyumba.co.ke/iNyumba/admin/bill/viewabillandbalances/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPendingData(response.data.response);
    } catch (error) {
      toast.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Pending Payments</h1>

          <div className="mb-4 flex gap-2">
            <input
              type="text"
              value={propertyId}
              onChange={(e) => setPropertyId(e.target.value)}
              placeholder="Enter Property ID"
              className="border px-3 py-2 rounded-md w-1/2"
            />
            <button
              onClick={fetchPendingData}
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              Fetch Data
            </button>
          </div>

          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className="bg-white shadow-md rounded-md p-4">
              <table className="min-w-full">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="py-2 px-4 border-b">Tenant Name</th>
                    <th className="py-2 px-4 border-b">Phone</th>
                    <th className="py-2 px-4 border-b">House Number</th>
                    <th className="py-2 px-4 border-b">Water Bill</th>
                    <th className="py-2 px-4 border-b">Rent Bill</th>
                    <th className="py-2 px-4 border-b">Trash Bill</th>
                    <th className="py-2 px-4 border-b">Billable Amount</th>
                    <th className="py-2 px-4 border-b">Amount Paid</th>
                    <th className="py-2 px-4 border-b">Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {pendingData.length > 0 ? (
                    pendingData.map((pending, index) => (
                      <tr key={index} className="hover:bg-gray-100">
                        <td className="py-2 px-4 border-b">{pending.user_name}</td>
                        <td className="py-2 px-4 border-b">{pending.phone}</td>
                        <td className="py-2 px-4 border-b">{pending.house_no}</td>
                        <td className="py-2 px-4 border-b">{pending.water_bill}</td>
                        <td className="py-2 px-4 border-b">{pending.rent_bill}</td>
                        <td className="py-2 px-4 border-b">{pending.trashbill}</td>
                        <td className="py-2 px-4 border-b">{pending.total_amount}</td>
                        <td className="py-2 px-4 border-b">{pending.amount_paid}</td>
                        <td className="py-2 px-4 border-b">{pending.balance}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="py-2 px-4 text-center">
                        No Pending Payments found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default PendingPayments;
