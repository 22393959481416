import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MonthlyStatementForm = () => {
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!month || !year || !propertyId) {
      toast.error("Please fill in all fields.");
      return;
    }

    const token = localStorage.getItem("token");
    const requestData = {
      month: parseInt(month, 10),
      year: parseInt(year, 10),
      property_id: propertyId,
    };

    try {
      setLoading(true);
      const response = await axios.post(
        "https://backend.inyumba.co.ke/iNyumba/monthlystatementreportmanual",
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Monthly statement generated successfully!");
      console.log(response.data);
    } catch (error) {
      toast.error("Failed to generate the statement. Try again.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-md rounded-lg mt-10">
      <h2 className="text-xl font-bold mb-4">Generate Monthly Statement</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-sm font-medium">Month</label>
          <select
            className="w-full mt-1 p-2 border rounded"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          >
            <option value="">Select Month</option>
            {Array.from({ length: 12 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {new Date(0, i).toLocaleString("default", { month: "long" })}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium">Year</label>
          <input
            type="number"
            className="w-full mt-1 p-2 border rounded"
            placeholder="Enter year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium">Property ID</label>
          <input
            type="text"
            className="w-full mt-1 p-2 border rounded"
            placeholder="Enter property ID"
            value={propertyId}
            onChange={(e) => setPropertyId(e.target.value)}
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
          disabled={loading}
        >
          {loading ? "Generating..." : "Generate Statement"}
        </button>
      </form>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default MonthlyStatementForm;
